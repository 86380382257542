import styled from 'styled-components';

const tall = `
grid-row-end: span 2;
@media (max-width: 760px) {
  grid-row-end: span 1;
}
`;

const visible = `
opacity: 1;
visibility: visible;
`;

export const Grid = styled.section`
  display: grid;
  margin: 0 auto;
  width: 100%;
  grid-template-columns: repeat(auto-fill, minmax(320px, 2fr));
  grid-auto-rows: minmax(300px, auto);
  grid-gap: 2rem;
  overflow: hidden;

  @media (max-width: 760px) {
    grid-auto-rows: minmax(400px, auto);
    grid-template-columns: none;
  }
`;

export const Item = styled.div`
  background-image: url(${(props) => props.bkg && props.bkg});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  flex: 1 1 200px;
  position: relative;
  color: white;
  width: 100%;
  height: 100%;
  cursor: pointer;
  border-radius: 3px;
  position: relative;
  ${(props) => props.tall && tall};

  &:hover {
    .overlay {
      opacity: 1;
      visibility: visible;
    }
  }

  .member-info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    font-size: 20px;
    gap: 10px;
    button {
      color: white;
      text-decoration: underline;
    }
  }

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    overflow: hidden;
  }
`;

export const Overlay = styled.div`
  display: flex;
  position: fixed;
  background-color: white;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  flex-direction: row;
  width: 100%;
  opacity: 0;
  visibility: hidden;
  transition: all 0.1s linear;
  ${(props) => (props.visible ? visible : '')}

  .wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    max-width: var(--header-width);
    width: 100%;
    gap: 3rem;
    margin: 3rem auto;

    .wrapper-image {
      width: 40%;
      height: 100%;
    }
  }

  .content-container {
    height: 100%;
    width: 50%;
    align-self: flex-start;
    display: flex;
    flex-direction: column;
    gap: 2rem;

    .close {
      position: absolute;
      top: 0;
      right: 0;
      font-size: 1rem;
      margin: 1rem;
    }

    h2 {
      font-size: 3.7rem;
      font-family: var(--font-bold);
    }

    span {
      font-size: 2.75rem;
      color: var(--primary-color);
    }

    p {
      font-family: var(--font-regular);
      padding-top: 2rem;
      font-size: 1.1rem;
      line-height: 2.5rem;
      text-align: justify;
    }
  }

  @media (max-width: 768px) {
    .wrapper {
      gap: 1rem;
      margin: 1.3rem;
      flex-direction: column;
      .wrapper-image {
        width: 100%;
        height: 200%;
      }
    }

    .content-container {
      width: 100%;
      gap: 0rem;

      h2 {
        font-size: 2.5rem;
      }

      span {
        font-size: 1.75rem;
      }

      p {
        line-height: 1.7rem;
        padding-top: 1.5rem;
      }
    }
  }
`;
