import styled from 'styled-components';
import Carousel from 'react-elastic-carousel';

export const SliderContainer = styled.div`
  position: absolute;
  width: 35%;
  margin: 3rem;
  bottom: 23px;
  background-color: transparent;
  transition: all 0.2s ease-in-out;

  .wrapper {
    position: relative;

    .content-container {
      background-color: white;
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 100%;
      gap: 1rem;
      padding: 2rem;

      h1 {
        font-family: var(--font-bold);
        font-size: 2rem;
        align-self: flex-start;
      }

      .button-block {
        display: inline-flex;
        align-items: center;
        gap: 20px;
      }

      button {
        align-self: flex-start;
      }
    }

    .arrow-wrapper {
      display: inline-flex;
      width: 100%;
      justify-content: space-between;
      flex-direction: row;
      padding-top: 0rem;

      > button {
        display: inline-flex;
        gap: 20px;
        justify-content: center;
        align-items: center;
        width: 100%;

        circle {
          fill: white;
        }

        path {
          stroke: black;
        }
      }
    }
  }

  @media screen and (max-width: 980px) {
    width: 65%;
    margin: 1rem;
    bottom: 0px;
    h1 {
      font-size: 1.5rem;
    }
  }

  @media screen and (max-width: 425px) {
    width: 100%;
    bottom: 0px;
    margin: 0;
    .wrapper {
      .content-container {
        padding: 0rem;
        background-color: transparent;

        > *:not(.button-block) {
          color: white;
        }

        h1 {
          font-size: 3.5rem;
          align-self: center;
          text-align: left;
          padding: 0rem 1rem;
        }

        p {
          display: none;
        }

        .button-block {
          gap: 0px;
          margin: 0;

          a,
          button {
            width: 100%;
            border: none;
          }
        }
      }
    }
  }
`;

export const HomeCarousel = styled(Carousel)`
  height: 100vh;

  .rec .rec-item-wrapper {
    img {
      height: 100vh;
      width: 100vw;
      object-fit: cover;
    }
  }

  .rec.rec-carousel {
    width: 100%;
    position: relative;

    .rec-slider-container {
      margin: 0;
    }
  }
`;
