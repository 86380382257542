import styled from 'styled-components';

export const FormContainer = styled.section`
  height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  iframe,
  form {
    max-width: 50%;
    width: 100%;
  }

  @media (max-width: 768px) {
    flex-direction: column-reverse;
    height: 100%;
    iframe,
    form {
      max-width: 100%;
    }
    iframe {
      height: 100vh;
    }
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  gap: 20px;
  padding: 2rem;

  .input-success {
    border: 4px solid #6ee7b7;
  }

  @media (max-width: 768px) {
    margin-top: 7rem;
    align-items: flex-start;
  }

  h1 {
    font-size: 3rem;
    font-family: var(--font-bold);
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    width: 100%;
  }

  button {
    margin-right: 0;
    text-align: center;
    align-self: center;
    width: 100%;

    &:disabled {
      opacity: 0.5;
    }
  }

  > div:last-child {
    padding: 0;
  }
`;
