import styled from 'styled-components';
import Carousel from 'react-elastic-carousel';

const visible = `
opacity: 1;
visibility: visible;
`;

export const ExclusivesContainer = styled.section`
  height: 100%;
  .ex-title {
    font-family: var(--font-bold);
    color: var(--primary-color);
    font-size: 3.5rem;
    line-height: 5rem;
    margin-bottom: 1rem;
  }

  .button-block {
    display: inline-flex;
    align-items: center;
    gap: 20px;
  }

  .arrow-wrapper {
    display: inline-flex;
    width: 100%;
    justify-content: space-between;
    flex-direction: row;
    padding-top: 0rem;

    > button {
      display: inline-flex;
      gap: 20px;
      justify-content: center;
      align-items: center;
      width: 100%;
    }
  }

  @media (max-width: 720px) {
    .ex-title {
      font-size: 3.5rem;
      line-height: 3.8rem;
      padding-bottom: 20px;
      text-align: left;
    }
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-start;
  height: 100%;
  width: 100%;
  gap: 2rem;

  .button-row {
    > button {
      display: inline-flex;
      gap: 20px;
      justify-content: center;
      align-items: center;
      width: 100%;
    }
  }

  @media (max-width: 1300px) {
    flex-direction: column;
    align-items: center;

    .product-container {
      order: 1;
    }
  }

  @media (max-width: 760px) {
    > * {
      width: 100%;
    }
  }

  .product-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 2rem;

    .product-extitle {
      font-size: 1.5rem;
      font-family: var(--font-bold);
    }

    .title {
      font-size: 3rem;
      font-family: var(--font-bold);
      color: var(--primary-color);
    }

    .description {
      p {
        text-align: justify;
        line-height: 25px;
        padding-bottom: 10px;
      }
    }
  }
`;

export const ProductCarousel = styled(Carousel)`
  .rec.rec-carousel {
    width: 100%;
    position: relative;

    .rec-slider-container {
      margin: 0;
    }
  }
`;

export const Overlay = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  background-color: white;
  opacity: 0;
  z-index: 100;
  visibility: hidden;
  transition: all 0.1s linear;
  ${(props) => (props.visible ? visible : '')}

  .wrapper {
    height: 80%;
    max-width: var(--header-width);
    width: 100%;
    margin: 0 auto;

    .video {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
      cursor: pointer;

      video {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .close {
      position: absolute;
      top: 0;
      right: 0;
      font-size: 1rem;
      margin: 1rem;
      z-index: 101;
    }
  }

  @media (max-width: 768px) {
    .wrapper {
      max-width: none;
      height: 100%;
      width: 100%;

      .close {
        background-color: white;
      }
    }
  }
`;

export const Play = styled.button`
  visibility: hidden;
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  padding: 2rem;
  background: rgba(0, 0, 0, 0.2);
  z-index: 100;
  transition: all 0.4s ease-in-out;
  font-family: var(--font-bold);
  color: white;
  font-size: 2rem;

  ${(props) => (props.visible ? visible : '')}
`;
