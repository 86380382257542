import React, { useContext } from "react";
import Portal from "../../../utils/portal";
import { Link } from "gatsby";
import { Menu } from "./styles";
import ProductContext from "../../../context/ProductContext";
import { GrClose } from "react-icons/gr";

export const MenuModal = ({ visible, close }) => {
	const { navigationPages } = useContext(ProductContext);
	return (
		<Menu visible={visible}>
			<button onClick={close}>
				<GrClose size={30} />
			</button>
			<button id="menu-book-button">Book Consultation</button>
			{navigationPages?.map(({ navTitle, navHandle, id }) => {
				const handle = !navHandle.startsWith("/")
					? `/${navHandle}`
					: navHandle;
				if (navHandle !== "/all-tiles") {
					return (
						<Link key={id} to={navHandle}>
							{navTitle}
						</Link>
					);
				}
			})}
		</Menu>
	);
};
