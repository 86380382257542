import React from "react";
import { FooterEl, Wrapper, Copyright, Logo, Item } from "./styles";
import { Link, graphql, useStaticQuery } from "gatsby";
import { FaLinkedin, FaFacebookSquare, FaInstagram } from "react-icons/fa";

const FooterItem = ({ data, itemTitle }) => {
	return (
		<Item>
			<ul>
				<h3>{itemTitle}</h3>
				{data.map((link) => (
					<li key={link.title}>
						<a
							href={link?.handle}
							target={link.isExternal ? "_blank" : "_self"}
							rel="noopener noreferrer"
						>
							{link.title}
						</a>
					</li>
				))}
			</ul>
		</Item>
	);
};

export const Footer = () => {
	const {
		allDatoCmsFooterLink,
		allDatoCmsSocial,
		allShopifyCollection,
		allShopifyProduct
	} = useStaticQuery(query);

	const componentRender = (title) => {
		if (title === "facebook") {
			return <FaFacebookSquare size="24px" />;
		} else if (title === "instagram") {
			return <FaInstagram size="24px" />;
		} else if (title === "linkedin") {
			return <FaLinkedin size="24px" />;
		} else {
			return title;
		}
	};

	const collections = allShopifyCollection.edges.map(({ node }) => {
		return {
			title: node.title,
			id: node.id,
			handle: `/tiles/${node?.handle}`
		};
	});

	const favourites = allShopifyProduct.edges.map(({ node }) => {
		return {
			title: node.title,
			id: node.id,
			handle: `/tiles/${node.collections[0]?.handle}/${node?.handle}`
		};
	});

	const info = allDatoCmsFooterLink.edges.map(({ node }) => {
		return {
			id: node.id,
			title: node.title,
			handle: node.path,
			isExternal:
				node.title === "Terms & Conditions" ||
				node.title === "Privacy Policy"
		};
	});

	return (
		<FooterEl>
			<Wrapper>
				<Link to="/">
					<Logo />
				</Link>
				<FooterItem data={collections} itemTitle="Tile Range" />
				<FooterItem data={favourites} itemTitle="Our Favourites" />
				<FooterItem data={info} itemTitle="Info" />
			</Wrapper>

			<Copyright>
				<div className="icons">
					{allDatoCmsSocial.edges.map(({ node }) => (
						<a
							key={node.id}
							href={node.url}
							target="_blank"
							rel="noopener noreferrer"
						>
							{componentRender(node.title.toLowerCase())}
						</a>
					))}
				</div>
				<small>© {new Date().getFullYear()}, Amalfi Tiles.</small>
			</Copyright>
		</FooterEl>
	);
};

export const query = graphql`
	{
		allDatoCmsFooterLink {
			edges {
				node {
					title
					path
					id
				}
			}
		}
		allDatoCmsSocial {
			edges {
				node {
					id
					title
					url
				}
			}
		}
		allShopifyCollection {
			edges {
				node {
					title
					handle
					id
				}
			}
		}
		allShopifyProduct(limit: 9, sort: { order: ASC, fields: updatedAt }) {
			edges {
				node {
					id
					title
					handle
					collections {
						handle
					}
				}
			}
		}
	}
`;
