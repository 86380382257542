import styled from "styled-components";

const visible = `
  opacity: 1;
  transform: translateX(0%);
  transition: transform 0.5s;
`;

const selected = `
color: var(--primary-color);
font-family: var(--font-bold);
`;

export const Option = styled.a`
	cursor: pointer;
	${(props) => props.selected && selected};
`;

export const FloatingAction = styled.button`
	position: fixed;
	bottom: 0;
	right: 0;
	margin: 2rem;
	padding: 1rem;
	color: white;
	font-family: var(--font-bold);
	background-color: black;
	z-index: 90;
	svg {
		stroke: white;
		fill: white;
		path {
			stroke: white;
			fill: white;
		}
	}
`;

export const FilterContainer = styled.div`
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	width: 60vw;
	z-index: 201;
	opacity: 0;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: flex-start;
	background-color: white;
	transform: translateX(-100%);
	transition: transform 0.5s, opacity 5s ease-out;
	${(props) => (props.visible ? visible : "")};

	.title-row {
		display: inline-flex;
		justify-content: space-between;
		align-items: center;
		padding: 2rem;
		h1 {
			font-size: 3rem;
		}
		svg {
			cursor: pointer;
		}
	}

	.filter {
		height: 100%;
	}

	.choices {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
	}

	.choice-options {
		display: flex;
		flex-direction: column;
		gap: 1rem;
		padding: 2rem;
		padding-top: 0rem;

		justify-content: space-between;
		align-items: flex-start;
	}

	.choice-container {
		padding: 2rem;
		width: 100%;
		padding-top: 0px;

		ul {
			column-count: 3;

			> li {
				margin: 10px 0px;
			}

			li:first-child {
				margin-top: 0px;
			}

			input {
				width: 20px;
			}
		}
	}

	.button-block {
		display: flex;
		width: 100%;

		button {
			width: 100%;
			border: none;
			padding: 1rem;
		}
	}

	@media (max-width: 1000px) {
		width: 100vw;
	}

	@media (max-width: 500px) {
		.choice-options {
			flex-direction: row;
		}
		.button-block,
		.choices {
			flex-direction: column;
		}
	}
`;
