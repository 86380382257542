import React, { useEffect, useState } from "react";
import { GatsbyImage as Image } from "gatsby-plugin-image";
import {
	CollectionItem,
	Grid,
	Wrapper,
	TileItem,
	List,
	InnerWrapper
} from "./CollectionGrid/styles";
import { Link, navigate } from "gatsby";
import { Button } from "../globalElements";
import ArrowRight from "../../assets/svg/arrow-right.svg";
import ArrowLeft from "../../assets/svg/arrow-left.svg";
import CaretRight from "../../assets/svg/caret-right.svg";
import {
	AvailabilityWrapper,
	CrumbLink
} from "../../templates/Product/ProductTemplate.styles";
import { AiOutlineCheck } from "react-icons/ai";
import { ImSpinner } from "react-icons/im";
import { getCurrentUser } from "../../utils/firebase/firebase.utils";
import axios from "axios";

const AvailabilityLabel = ({ totalInventory, availableForSale }) => {
	return (
		<AvailabilityWrapper>
			{totalInventory > 0 || availableForSale ? (
				<div className="available__items">
					<AiOutlineCheck fill="#fff" className="checkmark" />
					<span className="hightlight__text">Available</span>
				</div>
			) : (
				<span className="outofstock__text">Out of Stock</span>
			)}
		</AvailabilityWrapper>
	);
};

export const ProductGrid = ({ products, collection }) => {
	const [productList, setProductList] = useState([]);
	const [isLoading, setIsLoading] = useState();
	const [tag, setTag] = useState();

	// categorize products by tags
	const categorizedProducts = productList
		?.sort((a, b) => {
			return a.title.localeCompare(b.title);
		})
		?.reduce((acc, product) => {
			product?.tags?.forEach((tag) => {
				if (!acc[tag]) {
					acc[tag] = [];
				}
				acc[tag].push(product);
			});
			return acc;
		}, {});

	const fetchVulcanoProducts = async (products) => {
		const matches = products?.map((product) => {
			const regex = /(?<=AMALFI)\d+/;
			const id = product?.title?.match(regex);
			return id;
		});

		const productIds = matches
			?.map((match) => match && match[0])
			?.filter((id) => id);

		// keep array till id '1561'
		// const index = productIds?.indexOf("1561");
		// const productIdsToFetch = productIds?.slice(0, index + 1);

		setIsLoading(true);

		const response = await axios.post(
			"/.netlify/functions/cin7-stock",
			productIds
		);

		const vulcanoProducts = response?.data;

		const updatedProducts = products
			?.map((product) => {
				const regex = /(?<=AMALFI)\d+/;
				const id = product?.title?.match(regex);
				const productId = id && id[0];

				const vulcanoProduct =
					vulcanoProducts &&
					vulcanoProducts?.find((vulcanoProduct) =>
						vulcanoProduct?.productName?.includes(productId)
					);

				if (vulcanoProduct) {
					return {
						...product,
						totalInventory: Math.floor(vulcanoProduct?.available)
					};
				} else {
					return product;
				}
			})
			?.sort((a, b) => {
				return a.title.localeCompare(b.title);
			});

		setProductList(updatedProducts);

		setIsLoading(false);
	};

	useEffect(() => {
		if (tag) {
			fetchVulcanoProducts(categorizedProducts[tag]);
		}
	}, [tag]);

	useEffect(() => {
		fetchVulcanoProducts(products);
	}, []);

	useEffect(() => {
		const tag = new URLSearchParams(window.location.search).get("tag");
		setTag(tag);
	});

	useEffect(() => {
		if (tag) {
			setProductList(categorizedProducts[tag]);
		} else {
			setProductList(products);
		}
	}, [tag]);

	return (
		<Wrapper className="product-grid" as="section">
			<div className="breadcrumb" style={{ marginBottom: "1rem" }}>
				<CrumbLink to="/tiles"> Back to Tiles</CrumbLink> {"> "}
				<CrumbLink active={!tag} to={`/tiles/${collection?.handle}`}>
					{collection.title}
				</CrumbLink>
				{tag && (
					<>
						{" > "}
						<CrumbLink
							active={true}
							to={`/tiles/${collection?.handle}?tag=${tag}`}
						>
							{tag}
						</CrumbLink>
					</>
				)}
			</div>
			<h2 className="collection-title">{collection?.title}</h2>
			<InnerWrapper>
				<List>
					{!tag &&
					Object.keys(categorizedProducts).length > 0 &&
					collection?.handle !== "porcelain-stoneware"
						? Object.keys(categorizedProducts)?.map((tag) => (
								<li
									onClick={() =>
										navigate(
											`/tiles/${collection?.handle}?tag=${tag}`
										)
									}
									className="nav-block__item"
									key={tag}
								>
									<div className="nav-block__link">{tag}</div>
									<CaretRight width="15px" fill="black" />
								</li>
						  ))
						: productList?.map(
								({ id, handle, title, variants }) =>
									id && (
										<li
											onClick={() =>
												navigate(
													`/tiles/${collection?.handle}/${handle}`
												)
											}
											className="nav-block__item"
											key={id}
										>
											<div className="nav-block__link">
												{variants?.length === 1 &&
												variants[0]?.sku
													? variants[0]?.sku
													: title}
											</div>
											<CaretRight
												width="15px"
												fill="black"
											/>
										</li>
									)
						  )}
					<li className="nav-block__item">
						<Link className="nav-block__link" to="/tiles">
							Back to Collections
						</Link>
					</li>
				</List>

				<div className="collection-nav">
					<Button to="/tiles">
						<ArrowLeft fill="black" width="15px" />
						Back to Tiles
					</Button>
					<Button to="/all-tiles">
						Filter Tiles
						<ArrowRight fill="black" width="15px" />
					</Button>
				</div>

				<Grid>
					{!tag &&
					Object.keys(categorizedProducts).length > 0 &&
					collection?.handle !== "porcelain-stoneware"
						? Object.keys(categorizedProducts)?.map((tag) => (
								<div>
									<CollectionItem key={tag}>
										<Image
											image={
												categorizedProducts[tag][0]
													?.images[0]?.gatsbyImageData
											}
											alt={
												categorizedProducts[tag][0]
													?.title
											}
										/>
										<div className="overlay">
											<h3>{tag}</h3>
											<Button
												primary
												key={tag}
												to={`/tiles/${collection?.handle}?tag=${tag}`}
											>
												Go to {tag}{" "}
												<ArrowRight
													fill="black"
													width="15px"
												/>
											</Button>
										</div>
									</CollectionItem>
									{/* {getCurrentUser() && (
										<AvailabilityLabel
											totalInventory={totalInventory}
										/>
									)} */}
								</div>
						  ))
						: productList?.map(
								({
									id,
									title,
									images,
									handle,
									variants,
									totalInventory
								}) =>
									title === "title-box" ? (
										<TileItem key={id}>
											<h2>{collection.title}</h2>
											<div className="link-container">
												<Button
													primary={true}
													to="/tiles"
												>
													<ArrowLeft
														fill="black"
														width="15px"
													/>
													Back to Tiles
												</Button>
												<Button
													primary={true}
													to="/all-tiles"
												>
													Filter Tiles
													<ArrowRight
														fill="black"
														width="15px"
													/>
												</Button>
											</div>
										</TileItem>
									) : (
										<div>
											<CollectionItem key={id}>
												<Image
													image={
														images[0]
															?.gatsbyImageData
													}
													alt={
														images[0]
															?.gatsbyImageData
															?.alt ?? title
													}
												/>
												<div className="overlay">
													<h3>
														{variants?.length ===
															1 &&
														variants[0]?.sku
															? variants[0]?.sku
															: title}
													</h3>
													<Button
														primary
														key={id}
														to={`/tiles/${collection?.handle}/${handle}`}
													>
														Go to{" "}
														{variants?.length ===
															1 &&
														variants[0]?.sku
															? variants[0]?.sku
															: title}{" "}
														<ArrowRight
															fill="black"
															width="15px"
														/>
													</Button>
												</div>
											</CollectionItem>
											{getCurrentUser() &&
												(isLoading ? (
													<ImSpinner fill="#3a67b1" />
												) : (
													<AvailabilityLabel
														totalInventory={
															totalInventory
														}
														availableForSale={
															variants[0]
																?.availableForSale
														}
													/>
												))}
										</div>
									)
						  )}
				</Grid>
			</InnerWrapper>
		</Wrapper>
	);
};
