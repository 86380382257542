import styled, { css } from 'styled-components';
import { responsive } from '../../../context/theme';

export const VideoContainer = styled.section`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  ${responsive.md.query(css`
    height: 65vh;
  `)}
`;

export const Play = styled.div`
  visibility: hidden;
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  padding: 2rem;
  background: rgba(0, 0, 0, 0.2);
  z-index: 99;
  transition: all 0.4s ease-in-out;
  font-family: var(--font-bold);
  color: white;
  font-size: 2rem;

  ${({ visible }) =>
    visible &&
    css`
      visibility: visible;
      opacity: 1;
    `}
`;
