import styled, { css } from "styled-components";
import { Link } from "gatsby";
import { responsive } from "../../context/theme";
import { Wrapper } from "../../components/globalElements";
import { baseButton } from "../../styles/login.styles";

// export const PageContainer = styled.section`
//   display: flex;
//   justify-content: space-between;
//   flex-direction: column-reverse;
// .rec .rec-item-wrapper {
//   img {
//     height: ${({ responsiveHeight: height }) =>
//       height && `calc(100vh - ${height})`};
//     width: 100vw;
//     object-fit: cover;
//   }
// }

// .rec .rec-carousel {
//   position: relative;
//   width: 105%;

//   .rec-slider-container {
//     margin: 0;
//   }

//   > button {
//     position: absolute;
//     top: 50%;
//     color: white;
//     background-color: transparent;
//     z-index: 10;

//     > svg > g > circle {
//       fill: "white";
//     }
//     &:nth-child(1) {
//       right: calc(100% - 47px);
//       padding-left: 1rem;
//     }
//     &:last-child {
//       left: calc(100% - 47px);
//       padding-right: 1rem;
//     }
//   }
// }

// ${responsive.md.query(css`
//   .rec .rec-item-wrapper {
//     img {
//       height: 100vh;
//     }
//   }

//   flex-direction: row;
//   flex-basis: 50%;
//   padding: 0;
//   margin: 0;
// `)}
// `;

export const PageContainer = styled.section`
	background: red;
	display: flex;
	justify-content: space-between;
	flex-direction: row;
	.rec .rec-item-wrapper {
		img {
			height: ${({ responsiveHeight: height }) =>
				height && `calc(100vh - ${height})`};
			width: 100vw;
			object-fit: cover;
		}
	}

	.rec .rec-carousel {
		position: relative;
		width: 105%;

		.rec-slider-container {
			margin: 0;
		}

		> button {
			position: absolute;
			top: 50%;
			color: white;
			background-color: transparent;
			z-index: 10;

			> svg > g > circle {
				fill: "white";
			}
			&:nth-child(1) {
				right: calc(100% - 47px);
				padding-left: 1rem;
			}
			&:last-child {
				left: calc(100% - 47px);
				padding-right: 1rem;
			}
		}
	}

	${responsive.md.query(css`
		.rec .rec-item-wrapper {
			img {
				height: 100vh;
			}
		}

		flex-direction: row;
		flex-basis: 50%;
		padding: 0;
		margin: 0;
	`)}
`;

export const ProductWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	overflow: auto;

	${({ isRefin }) =>
		isRefin
			? css`
					height: 100%;
					padding-bottom: 2rem;
			  `
			: css`
					height: 100%;
			  `};

	h1,
	h2 {
		font-size: 0.8rem;
		display: block;

		${responsive.md.query(css`
			font-size: 2.5rem;
		`)}
	}

	.breadcrumb,
	.product-details {
		max-width: 85%;
		margin-bottom: 1.5rem;
		width: 100%;
	}

	.download-catalogue {
		order: 1;
		padding: 1rem;
		width: 100%;
	}

	.breadcrumb {
		order: 2;
		margin-top: 1.5rem;
		gap: 10px;
		text-align: left;
	}

	.heading {
		order: 3;
	}

	.circles-container {
		order: 4;
		display: flex;
		justify-content: flex-start;
		flex-wrap: wrap;
		position: absolute;
		gap: 10px;
		height: 200px;
		max-width: 90%;
		width: 100%;
		overflow-y: scroll;
		margin: 12px auto;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: 90;

		&__wrapper {
			display: flex;
			align-items: center;
			flex-direction: column;
			justify-content: flex-start;
			gap: 10px;
			width: 4rem;
		}

		&__info {
			width: 100%;
			color: black;
			text-align: center;
			font-size: 12px;
			max-width: 80%;
		}
	}

	.product-details {
		order: 5;

		&__list {
			display: flex;
			flex-direction: column;
			gap: 0.3rem;
		}

		&__title {
			display: none;
			padding-bottom: 20px;
			font-size: 2rem;
		}

		&__option-title {
			color: var(--primary-color);
			text-align: left;
			font-size: 1.8rem;
			margin: 0.5rem 0rem;
			margin-bottom: 1rem;
		}

		&__layout {
			display: flex;
			gap: 1.5rem;
		}

		&__list-title {
			margin-bottom: 0.5rem;
			white-space: nowrap;
		}
	}

	${responsive.md.query(css`
		max-width: 50%;
		margin-top: 125px;
		gap: 2.5rem;

		.mobile-heading {
			display: none;
		}

		.breadcrumb {
			margin: 0;
		}

		.download-catalogue {
			order: 4;
			max-width: 85%;
		}

		.circles-container {
			justify-content: flex-start;
			position: relative;
			gap: 10px;
			max-width: 85%;
			height: auto;
			overflow-y: unset;
			margin: 0rem;

			&__info {
				font-size: 0.85rem;
			}
		}

		.heading {
			margin-bottom: 0;
			&__title {
				margin-bottom: 1rem;
			}
		}
	`)}
`;

export const ProductTemplateContainer = styled.section`
	${responsive.md.query(css`
		margin-top: 96px;
	`)}
`;

// export const ProductTemplateWrapper = styled.div`
//   display: flex;
//   flex-direction: column-reverse;
//   align-items: center;
//   width: 100%;

//   .rec .rec-item-wrapper {
//     img {
//     }
//   }

// h1,
// h2 {
//   font-size: 2.5rem;
//   display: block;
// }

//   .breadcrumb,
//   .heading,
//   .product-details {
//     max-width: 85%;
//     margin-bottom: 1.5rem;
//     width: 100%;
//   }

//   .heading {
//     &__title {
//       padding-bottom: 1rem;
//     }
//   }

//   .mobile-heading {
//     order: 0;
//     position: absolute;
//     color: white;
//     font-size: 2.5rem;
//     text-align: left;
//     padding: 1rem;
//     max-width: 75%;
//     top: ${({ responsiveHeight }) => responsiveHeight};
//     left: 0;
//     right: 0;
//     z-index: 90;

//     &__option {
//       font-size: 2.5rem;
//       margin-bottom: 1rem;
//     }

//     &__product-title {
//       font-size: 2.5rem;
//       margin-bottom: 1rem;

//       &.option {
//         font-size: 1.8rem;
//         font-family: var(--font-regular);
//       }
//     }
//   }

//   .download-catalogue {
//     order: 1;
//     padding: 1rem;
//     width: 100%;
//   }

//   .breadcrumb {
//     order: 2;
//     margin-top: 1.5rem;
//     gap: 10px;
//     text-align: left;
//   }

//   .heading {
//     order: 3;
//   }

//   .circles-container {
//     order: 4;
//     display: flex;
//     justify-content: flex-start;
//     flex-wrap: wrap;
//     position: absolute;
//     gap: 10px;
//     height: 200px;
//     max-width: 90%;
//     width: 100%;
//     overflow-y: scroll;
//     margin: 12px auto;
//     bottom: 0;
//     left: 0;
//     right: 0;
//     z-index: 90;

//     &__wrapper {
//       display: flex;
//       align-items: center;
//       flex-direction: column;
//       justify-content: flex-start;
//       gap: 10px;
//       width: 4rem;
//     }

//     &__info {
//       width: 100%;
//       color: black;
//       text-align: center;
//       font-size: 12px;
//       max-width: 80%;
//     }
//   }

//   .product-details {
//     order: 5;

//     &__list {
//       display: flex;
//       flex-direction: column;
//       gap: 0.3rem;
//     }

//     &__title {
//       display: none;
//       padding-bottom: 20px;
//       font-size: 2rem;
//     }

//     &__option-title {
//       color: var(--primary-color);
//       text-align: left;
//       font-size: 1.8rem;
//       margin: 0.5rem 0rem;
//       margin-bottom: 1rem;
//     }

//     &__layout {
//       display: flex;
//       gap: 1.5rem;
//     }

//     &__list-title {
//       margin-bottom: 0.5rem;
//       white-space: nowrap;
//     }
//   }

//   ${responsive.md.query(css`
//     max-width: 50%;
//     gap: 2.5rem;

//     .mobile-heading {
//       display: none;
//     }

//     .breadcrumb {
//       margin: 0;
//     }

//     .download-catalogue {
//       order: 4;
//       max-width: 85%;
//     }

//     .circles-container {
//       justify-content: flex-start;
//       position: relative;
//       gap: 10px;
//       max-width: 85%;
//       height: auto;
//       overflow-y: unset;
//       margin: 0rem;

//       &__info {
//         font-size: 0.85rem;
//       }
//     }

//     .heading {
//       margin-bottom: 0;
//       &__title {
//         margin-bottom: 1rem;
//       }
//     }
//   `)}
// `;

export const ProductTemplateWrapper = styled(Wrapper)`
	display: flex;
	flex-direction: column;
	padding-top: 2rem;

	${responsive.md.query(css`
		gap: 5rem;
		flex-direction: row;
	`)}
`;

export const ProductWrapper1 = styled.div`
	display: flex;
	flex-direction: column;
	width: ${({ width }) => width || "100%"};
	margin: auto;
	margin-bottom: 2rem;
	gap: 1.5rem;

	${responsive.md.query(css`
		margin: unset;
	`)}

	${({ isRefin }) =>
		isRefin
			? css`
					height: 100%;
					padding-bottom: 2rem;
			  `
			: css`
					min-height: 100%;
			  `};

	h1,
	h2 {
		font-size: 1.4rem;

		${responsive.md.query(css`
			font-size: 2.3rem;
		`)}

		display: block;
	}

	h4 {
		font-size: 1.25rem;
		display: block;
	}

	.product__details {
		display: flex;
		justify-content: space-between;

		.hightlight__text {
			color: #3a67b1;
		}

		.outofstock__text {
			color: #8c8c8c;
		}

		.available__items {
			display: flex;
			align-items: center;

			.checkmark {
				margin-right: 0.75rem;
				font-weight: bold;
				padding: 0.2rem;
				min-width: 20px;
				min-height: 20px;
				border-radius: 50%;
				background: #3a67b1;
			}
		}
	}

	.selector__container {
		.custom__selector {
			&-root {
				position: relative;
			}
			&-control {
				display: flex;
				align-items: center;
				justify-content: space-between;
				margin-top: 0.5rem;
				padding: 10px 1rem;
				border: 2px solid #000000;
				width: 100%;
				cursor: pointer;

				:disabled {
					background-color: #c5c5c5;
					cursor: not-allowed;
					:hover {
						background-color: #c5c5c5;
						color: #ffffff;
					}
				}
			}
			&-placeholder {
				font-size: 14px;
				font-weight: normal;
				font-family: Arial;
			}
			&-menu {
				position: absolute;
				width: 100%;
				background-color: #fff;
				padding: 0.5rem;
				box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
				z-index: 1;
				max-height: 140px;
				overflow-y: auto;

				&::-webkit-scrollbar {
					width: 4px;
				}

				&::-webkit-scrollbar-track {
					box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
				}

				&::-webkit-scrollbar-thumb {
					background-color: darkgrey;
				}
			}
			&-option {
				padding: 0.5rem;
				cursor: pointer;

				&.is-selected,
				&:hover {
					font-weight: bold;
				}
			}
		}
	}

	.squares-container {
		display: flex;
		align-items: center;
		margin: 12px 0px;
		gap: 10px;

		&__wrapper {
			display: flex;
			align-items: center;
			flex-direction: column;
			justify-content: flex-start;
			gap: 10px;
			width: 4rem;
		}
	}

	.button__container {
		flex: 1;
		display: flex;
		align-items: end;
		padding-bottom: calc(74px + 4rem);
		gap: 1rem;

		${responsive.md.query(css`
			gap: 2rem;
		`)}

		.button-filled {
			${baseButton};
			font-family: Arial;
			text-align: center;
			background: #000000;
			color: #ffffff;
			font-weight: normal;
			max-width: 50%;
			:hover {
				background: #ffffff;
				border: 1px solid #000000;
				color: #000000;
			}

			${responsive.md.query(css`
				max-width: 200px;
			`)}
		}
		.button-unfilled {
			${baseButton};
			background: #ffffff;
			max-width: 200px;
			color: #000000;
			font-weight: normal;
			max-width: 50%;
			:hover {
				background: #f5f5f5;
				color: #000000;
			}

			${responsive.md.query(css`
				max-width: 200px;
			`)}
		}
	}

	.rec .rec-item-wrapper {
		max-height: 500px;
		max-width: 100%;
		img {
			object-fit: cover;
		}
	}

	.rec .rec-carousel {
		position: relative;

		.rec-slider-container {
			margin: 0px;
		}

		> button {
			position: absolute;
			top: 50%;
			z-index: 10;

			> svg > g > circle {
				fill: "white";
			}
			&:nth-child(1) {
				left: -30px;
			}
			&:last-child {
				right: -30px;
			}
		}
	}
`;

export const AvailabilityWrapper = styled.div`
	.available__items {
		display: flex;
		align-items: center;

		.checkmark {
			margin-right: 0.75rem;
			font-weight: bold;
			padding: 0.2rem;
			min-width: 20px;
			min-height: 20px;
			border-radius: 50%;
			background: #3a67b1;
		}
	}

	.hightlight__text {
		color: #3a67b1;
	}

	.outofstock__text {
		color: #8c8c8c;
	}
`;

export const Circle = styled.div`
	width: 50px;
	height: 50px;
	border-radius: 50%;
	position: relative;
	cursor: pointer;
	background-image: url(${(props) => props.bkg});
	background-position: center;
	background-size: cover;
`;

export const Square = styled.div`
	width: 50px;
	height: 50px;
	border-radius: 2px;
	position: relative;
	cursor: pointer;
	background-image: url(${(props) => props.bkg});
	background-position: center;
	background-size: cover;
`;

export const CrumbLink = styled(Link)`
	font-size: 15px;
	color: #727272;
	${({ active }) =>
		active &&
		`
  color: black;
  font-family: var(--font-bold);`};

	&:hover {
		color: var(--secondary);
		transition: color 2s;
	}
`;
