import React, { useState, useEffect } from 'react';
import { InputContainer } from './styles';
import { uniq } from 'lodash-es';
import { SearchGrid } from 'components';

export const Looks = ({ input, products }) => {
  const [looks, setLooks] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [query, setQuery] = useState('');

  useEffect(() => {
    setLooks(
      uniq(products.map((product) => product?.productType)).filter(
        (looks) => looks.length > 1
      )
    );
  }, [products]);

  useEffect(() => {
    setFiltered(products.filter((product) => product?.productType === query));
  }, [query, products]);

  return (
    <InputContainer>
      <div className='input-info'>
        <h2>{input.title}</h2>
      </div>
      <label htmlFor={input.id} hidden>
        {input.title}
      </label>
      <select id={input.id} onChange={(e) => setQuery(e.target.value)}>
        <option defaultValue value='-'>
          {input.placeholder}
        </option>
        {looks.map((value) => (
          <option key={value}>{value}</option>
        ))}
      </select>
      <SearchGrid query={query} results={filtered} />
    </InputContainer>
  );
};
