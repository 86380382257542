import firebase from "firebase/compat/app";
import "firebase/compat/auth";

const firebaseConfig = {
	apiKey: process.env.GATSBY_FR_API_KEY,
	authDomain: process.env.GATSBY_FR_AUTH_DOMAIN,
	databaseURL: process.env.GATSBY_FR_DB_URL,
	projectId: process.env.GATSBY_FR_PROJECT_ID,
	storageBucket: process.env.GATSBY_FR_STORAGE_BUCKET,
	messagingSenderId: process.env.GATSBY_FR_SENDER_ID,
	appId: process.env.GATSBY_FR_APP_ID
};

// Initialize Firebase
const firebaseApp = firebase.initializeApp(firebaseConfig);
const auth = firebaseApp.auth();
export { auth };
