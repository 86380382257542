import React from 'react';
import { BsQuestionCircle } from 'react-icons/bs';
import { NotFoundContainer } from './styles';
import { Link } from 'gatsby';

export const NotFound = () => (
  <NotFoundContainer>
    <BsQuestionCircle fill='var(--primary-blue)' size='30' />
    <h2>We're Sorry!</h2>
    <p>No products were found for your search.</p>
    <p>
      If you need to get in touch about a specific product,{' '}
      <Link to='/contact'>please contact us.</Link>
    </p>
  </NotFoundContainer>
);
