import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import Logo from "../../../assets/svg/header-logo.svg";
import { MenuModal, SearchModal, ContactModal } from "components";
import { Fixed, Inner } from "./Header.styles";
import { Button } from "../../globalElements";
import { GrPhone, GrSearch, GrTextAlignFull } from "react-icons/gr";
import useMedia from "../../../hooks/useMedia";
import { getCurrentUser } from "../../../utils/firebase/firebase.utils";

export const Header = () => {
	const [menu, setMenu] = useState(false);
	const [search, setSearch] = useState(false);
	const [contact, setContact] = useState(false);
	const [transparent, setTransparent] = useState(true);
	const [displayName, setDisplayName] = useState();
	const { isTablet } = useMedia();

	useEffect(() => {
		window.addEventListener("scroll", handleScroll);
		!isTablet && setTransparent(false);
	}, [isTablet]);

	useEffect(() => {
		if (getCurrentUser()) {
			setDisplayName(getCurrentUser().displayName);
		} else {
			setDisplayName(null);
		}
	});

	function handleScroll() {
		if (isTablet) {
			if (window.pageYOffset === 0) {
				setTransparent(true);
				return;
			}

			if (window.pageYOffset >= 1) {
				setTransparent(false);
			}
		}
	}

	return (
		<>
			<Fixed id="main-nav" transparent={transparent}>
				<Inner>
					<div className="icons">
						<div className="wrapper">
							<button onClick={() => setContact(!contact)}>
								<GrPhone
									style={{ path: { fill: "black" } }}
									size="25px"
								/>
							</button>

							<button onClick={() => setSearch(!search)}>
								<GrSearch size="25px" />
							</button>

							<button onClick={() => setMenu(!menu)}>
								<GrTextAlignFull size="30px" />
							</button>
						</div>
					</div>

					<div className="logo">
						<Link aria-label="Amalfi Tiles Logo" to="/">
							<Logo height="25px" />
						</Link>
					</div>

					<div className="booking btn-grp">
						{!displayName ? (
							<Link to="/login" className="header-portal">
								A&amp;D Portal
							</Link>
						) : (
							<Link to="/welcome-back" className="header-name">
								Hi {displayName}
							</Link>
						)}

						<Button
							href="https://calendly.com/amalfi-tiles"
							className="header-simply-book"
						>
							Book Consultation
						</Button>
					</div>
				</Inner>
			</Fixed>

			<ContactModal visible={contact} close={() => setContact(false)} />
			<MenuModal visible={menu} close={() => setMenu(false)} />
			<SearchModal visible={search} close={() => setSearch(false)} />
		</>
	);
};
