import React, { useState, useEffect } from 'react';
import { InputContainer, SizeContainer } from './styles';
import { SearchGrid } from 'components';
import { getSizes } from '../../utils/filter';

export const Size = ({ input, variants }) => {
  const [first, setFirst] = useState();
  const [second, setSecond] = useState();
  const [filtered, setFiltered] = useState([]);
  const { x, y } = getSizes(variants);

  useEffect(() => {
    handleFilter();
  }, [first, second]);

  const handleFilter = () =>
    setFiltered(
      variants.filter(
        (variant) => variant.selectedOptions[2]?.value === `${first}x${second}`
      )
    );

  return (
    <InputContainer>
      <div className='input-info'>
        <h2>{input.title}</h2>
      </div>

      <SizeContainer>
        <select onChange={(e) => setFirst(e.target.value.split(' ')[0])}>
          <option defaultValue value='-'>
            -
          </option>
          {x.map((value) => (
            <option key={value}>{value} mm</option>
          ))}
        </select>
        <p>by</p>
        <select onChange={(e) => setSecond(e.target.value.split(' ')[0])}>
          <option selected value='-'>
            -
          </option>
          {y.map((value) => (
            <option key={value}>{value} mm</option>
          ))}
        </select>
      </SizeContainer>
      <SearchGrid query={second} results={filtered} />
    </InputContainer>
  );
};
