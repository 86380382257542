import React, { useState, useEffect } from "react";
import { InputContainer } from "./styles";
import { SearchGrid } from "components";

export const ProductCode = ({ input, products }) => {
	const [filtered, setFiltered] = useState([]);
	const [query, setQuery] = useState("");

	useEffect(() => {
		if (!query) return;
		filterBySku();
	}, [query]);

	const filterBySku = () => {
		const search = query.toLowerCase().trim();

		const filtered = [];

		products.forEach((product) => {
			product.variants.forEach((variant) => {
				if (
					variant.sku &&
					variant.sku?.toLowerCase().trim() === search
				) {
					filtered.push({
						variant,
						handle: product.handle,
						title: product.title,
						id: product.id,
						collections: product.collections,
						images: product.images
					});
				}
			});
		});
		setFiltered(filtered);
	};

	return (
		<InputContainer>
			<div className="input-info">
				<h2>{input.title}</h2>
			</div>
			<label htmlFor={input.id} hidden>
				{input.title}
			</label>
			<input
				id={input.id}
				placeholder={input.placeholder}
				type="text"
				onChange={(e) => setQuery(e.target.value)}
				onKeyPress={(e) => {
					if (e.key === "Enter") {
						filterBySku();
					}
				}}
			/>
			<SearchGrid query={query} results={filtered} />
		</InputContainer>
	);
};
