import React, { useState, useRef } from 'react';
import { GatsbyImage as Image } from 'gatsby-plugin-image';
import { ProductCarousel, Container, ExclusivesContainer } from './styles';
import { myArrow, Button, Btn } from '../../globalElements';
import { VideoOverlay } from 'components';
import ArrowLeft from '../../../assets/svg/arrow-left.svg';
import ArrowRight from '../../../assets/svg/arrow-right.svg';

export const ExclusiveProducts = ({ exclusiveProducts }) => {
  const [video, setVideo] = useState({});
  const [visible, setVisible] = useState(false);
  const carousel = useRef(null);

  const handleVideo = (v) => {
    setVideo(v);
    setVisible(!visible);
  };

  const ProductContainer = ({ product }) => {
    return (
      <div className='product-container'>
        <h2 className='title'>{product.title}</h2>
        <div
          className='description'
          dangerouslySetInnerHTML={{ __html: product.description }}
        />
        <div className='button-block'>
          <Button primary={true} to={product.linkPath}>
            {product.linkTitle}
          </Button>
          <Btn
            primary={true}
            onClick={() =>
              handleVideo({
                src: product.videoLink,
                poster: product.image.gatsbyImageData,
              })
            }>
            Watch Video
          </Btn>
        </div>
      </div>
    );
  };

  const ImageContainer = ({ product, i }) => {
    return (
      <div className='image-container'>
        <Image
          image={product.image.gatsbyImageData}
          alt={product.image.alt ? product.image.alt : ''}
          height={400}
          style={{ height: '500px' }}
        />
        <div className='arrow-wrapper'>
          {exclusiveProducts[i - 1] && (
            <Btn onClick={() => carousel.current.slidePrev()}>
              <ArrowLeft fill='black' width='25px' /> Previous
            </Btn>
          )}
          {exclusiveProducts[i + 1] && (
            <Btn onClick={() => carousel.current.slideNext()}>
              Next
              <ArrowRight fill='black' width='25px' />
            </Btn>
          )}
        </div>
      </div>
    );
  };

  return (
    <>
      <ExclusivesContainer>
        <h2 className='ex-title'>Amalfi Exclusives</h2>
        <ProductCarousel
          renderArrow={myArrow}
          outerSpacing={0}
          ref={carousel}
          showArrows={false}
          pagination={false}
          enableMouseSwipe={false}>
          {exclusiveProducts.map((product, i) => {
            return (
              <Container key={product.id}>
                <ImageContainer product={product} i={i} />
                <ProductContainer product={product} />
              </Container>
            );
          })}
        </ProductCarousel>
      </ExclusivesContainer>
      {visible && (
        <VideoOverlay
          visible={visible}
          video={video}
          onClick={() => setVisible(!visible)}
        />
      )}
    </>
  );
};
