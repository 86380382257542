import styled from 'styled-components';

const visible = `
visibility: visible;
opacity: 1;
`;

export const Contact = styled.div`
  position: fixed;
  visibility: hidden;
  transition: all 0.3s linear;
  z-index: 100;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  opacity: 0;
  ${(props) => (props.visible ? visible : '')};

  h3 {
    font-size: 2rem;
    font-family: var(--font-bold);
  }

  > *:not(h3) {
    font-family: var(--font-regular);
    a {
      color: black;

      &:hover {
        color: var(--primary-color);
      }
    }
  }

  .container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background: white;
    height: 100%;
    width: 100%;
    gap: 0px;
    .content-wrapper {
      max-width: var(--header-width);
      width: 100%;
      align-self: center;
      padding: 3rem;
    }
  }

  .contact-details {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 1rem;

    #close {
      cursor: pointer;
    }

    span {
      display: inline-flex;
      align-items: center;
      gap: 10px;

      &:first-child {
        justify-content: space-between;
      }
    }
  }

  .opening-hours {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    gap: 1rem;

    > div {
      width: 50%;
      padding: 1rem;
      border: 3px solid black;
      color: black;
      font-size: 1rem;
      line-height: 1rem;
      min-height: 250px;

      h4 {
        font-size: 1.5rem;
        margin-bottom: 10px;
      }

      ul {
        list-style-type: square;
        margin-left: 20px;
        li {
          padding: 5px 0px;
          strong {
            font-family: var(--font-bold);
          }
        }
      }
    }
  }

  @media (max-width: 700px) {
    h3 {
      font-size: 1.5rem;
    }

    span {
      font-size: 16px;
    }

    .container {
      margin: 0;
      .content-wrapper {
        padding: 1.5rem;
      }
    }

    .opening-hours {
      gap: 25px;
      flex-direction: column;

      > div {
        width: 100%;
        padding: 0rem;
        font-size: 16px;
        border: none;
        min-height: auto;

        h4 {
          font-size: 18px;
          margin-bottom: 0px;
        }
        ul {
          li {
            padding: 0px;
          }
        }
      }
    }
  }
`;
