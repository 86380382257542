import styled from 'styled-components';

const visible = `
opacity: 1;
visibility: visible;
`;

export const VideoOverlayContainer = styled.section`
  position: fixed;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  background-color: white;
  opacity: 0;
  z-index: 100;
  visibility: hidden;
  transition: all 0.1s linear;
  ${(props) => (props.visible ? visible : '')}

  .wrapper {
    height: 100vh;
    width: 100vw;
    margin: 0 auto;

    .video {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
      cursor: pointer;

      video {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .close {
      position: absolute;
      top: 0;
      right: 0;
      border-radius: 50%;
      padding: 5px;
      margin: 10px;
      z-index: 101;
    }
  }
`;

export const Play = styled.button`
  position: absolute;
  visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  opacity: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  padding: 2rem;
  background: rgba(0, 0, 0, 0.2);
  z-index: 100;
  transition: all 0.4s ease-in-out;
  font-family: var(--font-bold);
  color: white;
  font-size: 2rem;
  svg {
    path {
      stroke: white;
    }
  }
  ${(props) => (props.visible ? visible : '')}
`;
