import styled from 'styled-components';

export const SearchGridContainer = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  height: calc(100vh - 300px);
  width: 100%;
  max-width: 100%;
  margin: 1rem 0rem;
  overflow-y: scroll;

  @media (max-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
  }

  @media (max-width: 960px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }
`;

export const Item = styled.div`
  color: white;
  border-radius: 2px;
  max-width: 100%;
  position: relative;
  height: 100%;
  width: 100%;

  &:hover {
    .overlay {
      opacity: 1;
      visibility: visible;
    }
  }

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    overflow: hidden;
  }
`;
