import React from "react";

import { Search, Header, Tabs, TabHeader, Overlay } from "./styles";
import { FaSearch } from "react-icons/fa";
import { GrClose } from "react-icons/gr";
import { Tab, TabList, TabPanel } from "react-tabs";
import { QuickSearch, ProductCode, Looks, Size } from "components";
import { graphql, useStaticQuery } from "gatsby";
import { flatten } from "lodash-es";

export const SearchModal = ({ visible, close }) => {
	const { allShopifyProduct } = useStaticQuery(query);
	const products = allShopifyProduct?.edges.map(({ node }) => node);
	const variants = flatten(products.map(({ variants }) => variants));

	return (
		<>
			<Overlay onClick={close} visible={visible} />
			<Search visible={visible}>
				<Header>
					<button
						id="exit"
						onClick={() => {
							close();
						}}
					>
						<GrClose size={30} />
					</button>
					<h2 style={{ color: "#fff" }}>
						<FaSearch fill="#fff" /> Search by
					</h2>
				</Header>

				<Tabs>
					<TabHeader>
						<TabList>
							<Tab>Quick Search</Tab>
							<Tab>Look</Tab>
							<Tab>Product Code</Tab>
							<Tab>Size</Tab>
						</TabList>
					</TabHeader>

					<TabPanel>
						<QuickSearch
							input={{
								title: "Quick Search",
								placeholder:
									"Search for your product through title (ie. Creos)",
								id: "search-by-product-title"
							}}
							products={products}
						/>
					</TabPanel>

					<TabPanel>
						<Looks
							input={{
								title: "Looks",
								placeholder:
									"Search by product look (ie. Brick)",
								id: "search-by-product-look"
							}}
							products={products}
						/>
					</TabPanel>

					<TabPanel>
						<ProductCode
							input={{
								title: "Product Code",
								placeholder:
									"Search by product code (AM-09-02)",
								id: "search-by-product-code"
							}}
							products={products}
						/>
					</TabPanel>

					<TabPanel>
						<Size
							input={{
								title: "Size Search",
								placeholder: "Search by product size",
								id: "search-by-product-size"
							}}
							variants={variants}
						/>
					</TabPanel>
				</Tabs>
			</Search>
		</>
	);
};

export const query = graphql`
	query {
		allShopifyProduct {
			edges {
				node {
					id
					title
					handle
					productType
					options {
						name
						values
					}
					images {
						id
						altText
						gatsbyImageData
					}
					collections {
						id
						handle
						title
					}
					variants {
						...ShopifyVariantFields
					}
				}
			}
		}
	}
`;
