import { useMediaQuery } from 'react-responsive';
import theme from '../context/theme';

export default function useMedia() {
  const { breakpoints } = theme;

  const isMobile = useMediaQuery({
    minWidth: breakpoints.sm.break,
  });

  const isTablet = useMediaQuery({
    minWidth: breakpoints.md.break,
  });

  const isDesktop = useMediaQuery({
    minWidth: breakpoints.lg.break,
  });

  const isLargeDesktop = useMediaQuery({
    minWidth: breakpoints.xl.break,
  });

  return { isMobile, isTablet, isDesktop, isLargeDesktop };
}
