import styled from 'styled-components';

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  height: 100vh;
  width: 100%;
  overflow-y: scroll;

  @media (max-width: 1200px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (max-width: 760px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 600px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const Item = styled.div`
  position: relative;
  color: white;
  border-radius: 2px;
  max-width: 100%;
  width: 100%;
  height: 100%;
  cursor: pointer;
  margin: 0 auto;
  border-radius: 3px;

  &:hover {
    .overlay {
      opacity: 1;
      visibility: visible;
    }
  }

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    overflow: hidden;
  }
`;
