import React, { useState, useEffect } from "react";
import { Link, navigate } from "gatsby";
import { GatsbyImage as Image } from "gatsby-plugin-image";
import {
	CollectionItem,
	Grid,
	Wrapper,
	TileItem,
	List,
	InnerWrapper
} from "./styles";
import { Button } from "../../globalElements";
import ArrowRight from "../../../assets/svg/arrow-right.svg";
import CaretRight from "../../../assets/svg/caret-right.svg";

export const CollectionGrid = ({ data: collections }) => {
	const [first, setFirst] = useState([]);
	const [second, setSecond] = useState([]);

	// extract unique tags from all products in collection and add to tags property in collection
	const addTagsToCollection = (collection) => {
		const tags = [];
		collection.products.forEach((product) => {
			product.tags.forEach((tag) => {
				if (!tags.includes(tag)) {
					tags.push(tag);
				}
			});
		});
		collection.tags = tags;
		return collection;
	};

	useEffect(() => {
		if (collections) {
			//first is porcelain stoneware, get the id from graphql
			// const first = "9fec7522-5991-5e48-98aa-7390f3efc0c4";
			// collections.sort((a, b) => {
			// 	if (a.id === first) {
			// 		return -1;
			// 	} else if (b.id === first) {
			// 		return 1;
			// 	} else {
			// 		return 0;
			// 	}
			// });

			// sort by title
			collections?.sort((a, b) => {
				return a.title.localeCompare(b.title);
			});

			setFirst(collections.slice(0, 2));
			setSecond(collections.slice(2, collections.length));

			// add tags to collection
			collections.forEach((collection) => {
				addTagsToCollection(collection);
			});
		}
	}, [collections]);

	return (
		<Wrapper>
			<h2 className="collection-title">Collections</h2>
			<InnerWrapper>
				<List isCollection={true}>
					{collections.map(({ id, handle, title, tags }) => (
						<li
							onClick={() => navigate(`/tiles/${handle}`)}
							className="nav-block__item"
							key={id}
						>
							<div className="nav-block__link">{title}</div>

							<CaretRight width="15px" fill="black" />
						</li>
					))}
				</List>
				<Grid>
					{first?.map(({ image, handle, id, title }) => (
						<CollectionItem>
							<Image
								width={100}
								image={image?.gatsbyImageData}
								alt={image?.alt === null ? "" : image?.alt}
							/>
							<div className="overlay">
								<h3>{title}</h3>
								<Button
									primary
									key={id}
									to={`/tiles/${handle}`}
								>
									Go to {title}{" "}
									<ArrowRight fill="black" width="15px" />
								</Button>
							</div>
						</CollectionItem>
					))}
					<TileItem>
						<h2>Tiles</h2>
					</TileItem>
					{second?.map(({ image, handle, id, title }) => (
						<CollectionItem>
							<Image
								image={image?.gatsbyImageData}
								alt={image?.alt === null ? "" : image?.alt}
							/>
							<div className="overlay">
								<h3>{title}</h3>
								<Button
									primary
									key={id}
									to={`/tiles/${handle}`}
								>
									Go to {title}{" "}
									<ArrowRight fill="black" width="15px" />
								</Button>
							</div>
						</CollectionItem>
					))}
				</Grid>
			</InnerWrapper>
		</Wrapper>
	);
};
