import React, { useState } from 'react';
import { Btn, Wrapper } from '../globalElements';
import { Form, FormContainer } from './ContactForm.styles';

export const ContactForm = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');

  const validateEmail = (email) => {
    return new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(email);
  };

  const validatePhone = (number) => {
    return new RegExp(
      /^(?:\+?(61))? ?(?:\((?=.*\)))?(0?[2-57-8])\)? ?(\d\d(?:[- ](?=\d{3})|(?!\d\d[- ]?\d[- ]))\d\d[- ]?\d[- ]?\d{3})$/
    ).test(number);
  };

  return (
    <FormContainer as='section'>
      <iframe
        title='Google Maps of Amalfi Tiles Location'
        height='100%'
        width='100%'
        style={{ border: '0' }}
        loading='lazy'
        allowfullscreen
        src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3310.304929918933!2d151.22851495186066!3d-33.933284529746985!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b12b18054c7595f%3A0x65e680e431a89e9!2sAmalfi%20Tiles!5e0!3m2!1sen!2sph!4v1613955044753!5m2!1sen!2sph'
      ></iframe>

      <Form
        method='POST'
        action='/contact'
        data-netlify='true'
        name='contactForm'
        data-netlify-honeypot='bot-field'
      >
        <input type='hidden' name='form-name' value='contactForm' />
        <h1>Contact Us</h1>
        <label htmlFor='name' hidden>
          Name
        </label>
        <input
          type='text'
          onChange={(e) => setName(e.target.value)}
          className={name.length >= 1 && 'input-success'}
          name='name'
          placeholder='Name'
        />
        <div>
          <label htmlFor='phone' hidden>
            Phone
          </label>
          <input
            onChange={(e) => setPhone(e.target.value)}
            className={validatePhone(phone) && 'input-success'}
            type='number'
            name='phone'
            placeholder='Phone '
          />
          <label htmlFor='email' hidden>
            Email
          </label>
          <input
            type='email'
            onChange={(e) => setEmail(e.target.value)}
            className={validateEmail(email) && 'input-success'}
            name='email'
            placeholder='Email'
          />
        </div>

        <label htmlFor='message' hidden>
          Message
        </label>
        <textarea
          onChange={(e) => setMessage(e.target.value)}
          className={message.length > 1 && 'input-success'}
          name='message'
          placeholder='How can we help?'
          rows='10'
        ></textarea>

        <Btn
          disabled={
            message?.length < 1 ||
            name?.length < 1 ||
            !validateEmail(email) ||
            !validatePhone(phone)
          }
        >
          Send
        </Btn>
        <div data-netlify-recaptcha='true' />
      </Form>
    </FormContainer>
  );
};
