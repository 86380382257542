import styled, { css } from "styled-components";
import { responsive } from "../../../context/theme";
import { HeaderWrapper } from "../../globalElements";

export const Fixed = styled.header`
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	z-index: 100;
	width: 100%;
	transition: all 0.2s;
	background-color: ${({ transparent }) =>
		transparent ? "transparent" : "white"};
	padding: 1rem 0rem;
`;

export const Inner = styled(HeaderWrapper)`
	display: flex;
	flex-direction: column;
	align-items: center;

	gap: 20px;
	transition: all 0.3s ease;
	padding: 0.75rem 0;

	> * {
		flex: 1;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.icons {
		.wrapper {
			gap: 16px;
			display: flex;
		}
	}

	.booking {
		display: flex;
		justify-content: center;
		margin-right: 0px;
	}

	.btn-grp {
		display: flex;
		flex-wrap: wrap-reverse;
		flex-direction: row;
		gap: 10px;
	}

	.header-portal {
		top: 65px;
		justify-content: center;
		left: 0;
		z-index: 200;
		background: #ffffff;
		border: 1px solid #000000;
		color: #000000;
		padding: 11px 40px;
		width: auto;
	}

	.header-name {
		top: 65px;
		justify-content: center;
		left: 0;
		z-index: 200;
		background: #ffffff;
		border: 1px solid #000000;
		color: #3a67b1;
		padding: 11px 40px;
		width: auto;
		font-weight: bold;
	}

	.header-simply-book {
		background: black;
		top: 65px;
		justify-content: center;
		left: 0;
		z-index: 200;
		color: white;
		width: auto;
	}

	.logo {
		justify-content: center;
		svg {
			width: 120px;
		}
	}

	${responsive.md.query(css`
		flex-direction: row;

		.logo {
			svg {
				width: 150px;
			}
		}

		.icons {
			margin-right: auto;
			justify-content: flex-start;
			.wrapper {
				display: inline-flex;
			}
		}

		.booking {
			margin-left: auto;
			justify-content: flex-end;
		}
	`)}
`;
