import React from 'react';
import styled, { css } from 'styled-components';
import { responsive } from '../../context/theme';

const SplitTextWrapper = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  font-family: var(--font-regular);
  gap: 1.5rem;
  margin: 5rem auto;

  h2 {
    width: 100%;
    flex-wrap: wrap;
    overflow-wrap: break-word;
    font-family: var(--font-bold);
    color: var(--primary-color);
    font-size: 2.5rem;
  }

  div {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    p {
      text-align: justify;
      line-height: 25px;
      padding-bottom: 20px;
    }
  }

  ${responsive.md.query(css`
    flex-direction: row;

    & > * {
      max-width: 50%;
    }

    h2 {
      font-size: 3.5rem;
    }
  `)}
`;

export const TextSplit = ({ children }) => (
  <SplitTextWrapper as='section'>{children}</SplitTextWrapper>
);
