import styled from 'styled-components';

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  margin: 10px 0;

  .input-info {
    padding: 10px 0;
    line-height: 1.5rem;
    h2 {
      font-size: 1.5rem;
      padding-bottom: 2px;
    }
  }

  input {
    outline: 0;
  }

  .tag-container {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
    gap: 10px;

    .tag {
      background-color: var(--primary-color);
      color: white;
      font-size: 15px;
      padding: 0.6rem;
      border-radius: 4px;
    }
  }
`;

export const SizeContainer = styled.div`
  display: flex;
  gap: 20px;
  justify-content: space-between;
  align-items: center;

  p {
    font-family: var(--font-bold);
  }
  select {
    width: 50%;
  }
`;

export const Wrapper = styled.div`
  margin: 20px 0;

  .title-container {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;

    .show-looks {
      display: none;
    }

    @media (max-width: 760px) {
      .show-looks {
        display: block;
      }

      &.show {
        display: flex;
      }
    }
    h2 {
      font-size: 1.5rem;
      padding: 10px 0;
    }
  }
`;

export const NotFoundContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 65vh;

  svg {
    margin-bottom: 10px;
  }

  > *:not(svg) {
    text-align: center;
    max-width: 80%;
    padding: 5px;
  }

  h2 {
    font-family: var(--font-bold);
    font-size: 1.5rem;
  }

  p,
  a {
    font-family: var(--font-regular);
  }

  @media (max-width: 760px) {
    > *:not(svg) {
      max-width: 100%;
    }
  }
`;
