import { toast } from "react-toastify";
import { auth } from ".";

const logInWithEmailAndPassword = async (email, password) => {
	try {
		const res = await auth.signInWithEmailAndPassword(email, password);
		console.log(res);
		localStorage.setItem("user", JSON.stringify(res?.user));
		return res;
	} catch (err) {
		console.error(err);
		toast.error(
			"Some error occured while logging a user, please try again"
		);
	}
};

const signUpWithEmailAndPassword = async (username, email, password) => {
	try {
		const res = await auth
			.createUserWithEmailAndPassword(email, password)
			.then(async function (result) {
				return result.user
					.updateProfile({
						displayName: username
					})
					.catch(function (error) {
						console.log(error);
					});
			});
		return res;
	} catch (err) {
		console.error(err);
		toast.error(
			"Some error occured while logging a user, please try again"
		);
	}
};

const getCurrentUser = () => {
	const user =
		typeof window != "undefined" &&
		JSON.parse(localStorage.getItem("user"));
	// console.log("user", user);
	return user;
};

const logout = async () => {
	await auth.signOut();
	localStorage.removeItem("user");
	toast.success("User logged out successfully!");
};
export {
	logout,
	logInWithEmailAndPassword,
	signUpWithEmailAndPassword,
	getCurrentUser
};
