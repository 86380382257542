import React, { useState, useEffect } from "react";
import { Item, SearchGridContainer } from "./styles";
import { Button } from "../../globalElements";
import { NotFound } from "components";
import { GatsbyImage as Image } from "gatsby-plugin-image";

export const SearchGrid = ({ scrollHeight, query, results = [] }) => {
	const [filteredResults, setFilteredResults] = useState([]);
	useEffect(() => {
		setFilteredResults(
			results.map(
				({
					id,
					title,
					handle,
					collections,
					product,
					images,
					image
				}) => ({
					id,
					image:
						(images && images[0]?.gatsbyImageData) ||
						(image && image?.gatsbyImageData),
					title: title,
					handles: {
						product: handle || product?.handle,
						collection: collections
							? collections[0]
							: product?.collections[0]
					}
				})
			)
		);
	}, [results]);

	console.log("filteredResults", filteredResults);

	return (
		<>
			{query?.length >= 1 && (
				<>
					{results.length >= 1 ? (
						<SearchGridContainer scrollHeight={scrollHeight}>
							{filteredResults?.map(
								({ id, image, title, handles }) => {
									const { collection } = handles;
									//every product needs a collection, kept here to safe guard users selecting a product that has no place on the site
									if (collection && collection?.handle) {
										return (
											<Item key={id}>
												<Image
													image={image}
													style={{ height: "100%" }}
												/>
												<div className="overlay">
													<h2>{title}</h2>
													<Button
														primary={true}
														to={`/tiles/${handles?.collection?.handle}/${handles?.product}`}
													>
														Go to {title}
													</Button>
												</div>
											</Item>
										);
									}
								}
							)}
						</SearchGridContainer>
					) : (
						<NotFound />
					)}
				</>
			)}
		</>
	);
};
