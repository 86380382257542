import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Contact } from './styles';
import { FaPhone, FaAddressCard, FaEnvelope } from 'react-icons/fa';
import { GrClose } from 'react-icons/gr';

export const ContactModal = ({ visible, close }) => {
  const { datoCmsOpening: contact } = useStaticQuery(query);

  return (
    <Contact visible={visible}>
      <div className='container'>
        <div className='content-wrapper'>
          <div className='contact-details'>
            <span>
              <h3>Contact Details</h3>
              <GrClose size={30} id='close' onClick={() => close()} />
            </span>

            <span>
              <FaAddressCard /> {contact.address}
            </span>
            <span>
              <FaPhone />
              <a
                href={`tel: ${contact.phone}`}
                target='_blank'
                rel='noreferrer noopener'
              >
                {contact.phone}
              </a>
            </span>

            <span>
              <FaEnvelope />
              <a
                href={`mailto: ${contact.email}`}
                target='_blank'
                rel='noreferrer noopener'
              >
                {contact.email}
              </a>
            </span>
          </div>
          <div className='opening-hours'>
            <div>
              <h4>Showroom</h4>
              <p>{contact.showroomNotes}</p>
              <ul>
                <li>
                  Monday to Friday: <strong>{contact.showroomWeekdays}</strong>
                </li>
                <li>
                  Saturday: <strong>{contact.showroomSaturdays}</strong>
                </li>
                <li>
                  Sunday: <strong>{contact.showroomSundays}</strong>
                </li>
              </ul>
            </div>

            <div>
              <h4>Trade Center</h4>
              <p>{contact.tradeNote}</p>
              <ul>
                <li>
                  Monday to Friday: <strong>{contact.tradeWeekdays}</strong>
                </li>
                <li>
                  Saturday: <strong>{contact.tradeSaturday}</strong>
                </li>
                <li>
                  Sunday: <strong>{contact.tradeSunday}</strong>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <iframe
          title='Amalfi Tiles Google Maps'
          width='100%'
          height='100%'
          style={{ border: '0' }}
          loading='lazy'
          src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3310.304929918933!2d151.22851495186066!3d-33.933284529746985!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b12b18054c7595f%3A0x65e680e431a89e9!2sAmalfi%20Tiles!5e0!3m2!1sen!2sph!4v1613955044753!5m2!1sen!2sph'
        />
      </div>
    </Contact>
  );
};

export const query = graphql`
  {
    datoCmsOpening {
      email
      address
      phone
      showroomNotes
      showroomSaturdays
      showroomSundays
      showroomWeekdays
      tradeNote
      tradeSaturday
      tradeSunday
      tradeWeekdays
    }
  }
`;
