import styled, { css } from "styled-components";
import { responsive } from "../../../context/theme";
import { Wrapper as WrapperBase } from "../../globalElements";

const tileStyles = css`
	.collection-nav {
		padding-bottom: 2rem;
	}

	.nav-block {
		margin-right: 2rem;
		&__item {
			padding-bottom: 5px;
		}
	}
`;

const collectionStyles = css`
	.nav-block {
		padding-bottom: 0;
	}
`;

export const Wrapper = styled(WrapperBase)`
	padding-top: 2rem;

	.collection-nav {
		display: flex;
		flex-direction: column;
		gap: 1rem;
		padding: 1rem 0;
		> a {
			display: inline-flex;
			justify-content: space-between;
			align-items: center;
			gap: 1rem;
		}
	}

	h1,
	h2 {
		font-size: 2.5rem;
		font-family: var(--font-bold);
	}

	.collection-title {
		display: block;
		text-align: center;
		font-size: 20px;
	}

	${responsive.sm.query(css`
		.collection-title {
			font-size: 24px;
		}
	`)}

	${responsive.md.query(css`
		.collection-nav,
		.product-grid__title {
			display: none;
		}
		.collection-title {
			text-align: left;
			font-size: 35px;
		}
	`)}
`;

export const InnerWrapper = styled.div`
	display: flex;
	flex-direction: column;
	padding-top: 2rem;

	${responsive.md.query(css`
		flex-direction: row;
		justify-content: space-between;
		gap: 2rem;
	`)}
`;

export const List = styled.ul`
	display: none;
	width: auto;
	height: 100%;
	margin-bottom: 2rem;

	.nav-block {
		padding-top: 0px;
		padding-bottom: 2rem;

		&__title {
			font-size: 1.5rem;
			padding-bottom: 0.625rem;
		}

		&__item {
			cursor: pointer;
			padding: 0.5rem 1rem;
			border: 1px solid #000;
			margin-bottom: 1rem;
			display: flex;
			justify-content: space-between;
			align-items: center;
			min-height: 45px;
		}

		&__item:hover {
			background-color: #000;
			color: #fff;

			.caret-right {
				fill: white;
			}
		}

		&__link {
			font-size: 0.9rem;
			color: var(--primary-colour);
			margin-right: 1rem;
		}
	}

	${({ isCollection }) => (isCollection ? collectionStyles : tileStyles)}

	${responsive.lg.query(css`
		display: block;
	`)}
`;

export const Grid = styled.section`
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	height: 100%;
	grid-gap: 3rem;
	margin-bottom: 5rem;

	${responsive.sm.query(css`
		grid-template-columns: repeat(3, 1fr);
	`)}
`;

export const CollectionItem = styled.div`
	position: relative;
	color: white;
	cursor: pointer;
	margin: 0 auto;
	max-width: 100%;
	width: 100%;
	height: 100%;

	img {
		width: 100%;
		height: auto;
		object-fit: cover;
	}

	&:hover {
		.overlay {
			opacity: 1;
			visibility: visible;
		}
	}

	a {
		display: inline-flex;
		align-items: center;
		gap: 10px;
	}

	${responsive.lg.query(css`
		img {
			width: 261px;
			height: 261px;
			min-height: 261px;
			min-width: 261px;
		}
	`)}
`;

export const TileItem = styled.div`
	background-color: var(--primary-color);
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 2rem;
	max-height: 261px;
	max-width: 261px;

	h2 {
		text-align: left;
		color: white;
		font-size: 28px;
	}

	.link-container {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		gap: 10px;
		a {
			display: inline-flex;

			align-items: center;
			gap: 20px;
		}
	}

	@media (max-width: 760px) {
		display: none;
	}
`;
