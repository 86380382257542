import styled, { css } from "styled-components";
import { responsive } from "../context/theme";

const baseButton = css`
	line-height: 23px;
	padding: 10px 1rem;
	border: 1px solid #000000;
	width: 100%;
	font-weight: 700;
	font-size: 15px;
	cursor: pointer;

	:disabled {
		background-color: #c5c5c5;
		cursor: not-allowed;
		:hover {
			background-color: #c5c5c5;
			color: #ffffff;
		}
	}
`;

const OnBoardStyled = css`
	margin: 0px auto;
	width: 680px;
	display: flex;
	justify-content: space-evenly;
	min-height: 70vh;
	.button-filled {
		${baseButton};
		text-align: center;
		background: #000000;
		color: #ffffff;
		:hover {
			background: #ffffff;
			border: 1px solid #000000;
			color: #000000;
		}
	}
	.button-unfilled {
		${baseButton};
		text-align: center;
		background: #ffffff;
		color: #000000;
		:hover {
			background: #f5f5f5;
			color: #000000;
		}
	}

	.middle-line {
		border: 1px solid #000000;
		width: 189px;
		align-items: center;
		margin-top: 62px;
		margin-bottom: 57px;
		margin-left: auto;
		margin-right: auto;
	}

	.error-msg {
		margin-top: -11px;
		font-size: 12px;
		color: red;
	}
`;

const WelcomeStyled = styled.div`
	${OnBoardStyled};
	/* margin-top: 83px; */

	.heading {
		span {
			color: #3a67b1;
		}
		font-family: "Sofia W01 Regular";
		font-style: normal;
		text-align: center;
		color: #000000;
		font-weight: 800;
		font-size: 20px;
	}

	${responsive.lg.query(css`
		.heading {
			font-weight: 500;
			font-size: 40px;
			line-height: 74px;
		}
	`)}

	.flex-box {
		display: flex;
		flex-direction: row;
		width: 100%;
	}
`;

const LoginStyled = styled.div`
	${OnBoardStyled};

	margin-top: 83px;
	.heading {
		font-weight: 800;
		font-size: 35px;
		line-height: 54px;
		text-align: center;
		color: #000000;
	}
	.paragraph {
		text-align: center;
		font-weight: 500;
		font-size: 16px;
		line-height: 25px;
		margin-top: 22px;
		margin-bottom: 22px;
	}

	${responsive.md.query(css`
		.paragraph {
			margin-left: 30px;
			margin-right: 30px;
		}
	`)}

	.flex-box {
		display: flex;
		flex-direction: row;
		width: 100%;
	}
`;

const Gap = styled.div`
	margin-top: ${(props) => (props.mtMob ? props.mtMob : "10px")};

	${responsive.md.query(css`
		margin-top: ${(props) => (props.mtDesktop ? props.mtDesktop : "10px")};
	`)}
`;

export { LoginStyled, Gap, OnBoardStyled, WelcomeStyled, baseButton };
