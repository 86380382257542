import styled, { css } from 'styled-components';
import { Link } from 'gatsby';
import React from 'react';
import ArrowLeft from '../assets/svg/arrow-left.svg';
import ArrowRight from '../assets/svg/arrow-right.svg';
import { consts } from 'react-elastic-carousel';
import { responsive } from '../context/theme';

const container = `
max-width: var(--container-width);
width: 100%;
margin: 0 auto;
`;

export const myArrow = ({ type, onClick, isEdge }) => {
  const pointer =
    type === consts.PREV ? (
      <ArrowLeft width='50' height='50' />
    ) : (
      <ArrowRight width='50' height='50' />
    );
  return (
    <button onClick={onClick} disabled={isEdge}>
      {pointer}
    </button>
  );
};

export const Row = styled.section`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: ${(props) =>
    props.spaceBetween ? 'space-between' : 'center'};
  height: ${(props) => (props.hero ? 'calc(100vh - 65px)' : '100%')};
  width: 100%;
  flex-direction: ${(props) => (props.col ? 'column' : 'row')};
  overflow: hidden;
  margin: 0;
  ${(props) => (props.container ? container : '')}
`;

export const Col = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.row ? 'row' : 'column')};
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

export const Container = styled.div`
  margin: 0 auto;
  max-width: var(--header-width);
  width: 100%;
`;

export const ProductGrid = styled.section`
  display: grid;
  grid-gap: 5px;
  grid-template-columns: 1fr;
  padding: 1rem;

  @media (min-width: 384px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media (min-width: 1024px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`;

const globalBtn = css`
  line-height: 20px;
  padding: 10px 1rem;
  vertical-align: middle;
  cursor: pointer;
  font-family: var(--font-bold);
  font-size: 15px;
  transition: all 0.15s;
  text-decoration: none;
  text-align: center;
  background-color: var(--dark-blue);
  color: #fff;

  &:hover {
    background-color: white;
    color: var(--dark-blue);
  }
`;

const primary = css`
  background-color: white;
  color: var(--dark-blue);
  border: 2px solid black;
  &:hover {
    background-color: var(--dark-blue);
    color: #fff;
  }
`;

const secondary = `
    background-color: green;
`;

export const Btn = styled.button`
  ${globalBtn}
  ${(props) => (props.primary ? primary : props.secondary ? secondary : '')}
`;

export const Button = styled(Link)`
  ${globalBtn}
  ${(props) => (props.primary ? primary : props.secondary ? secondary : '')}
`;

export const Ahref = styled.a`
  ${globalBtn}
  ${(props) => (props.primary ? primary : props.secondary ? secondary : '')}
`;

export const WrapperStyles = css`
  margin-left: auto;
  margin-right: auto;
  max-width: ${({ theme: { breakpoints } }) => breakpoints.default};

  @media (min-width: ${({ theme: { breakpoints } }) => breakpoints.sm.break}px) {
    max-width: ${({ theme: { breakpoints } }) => breakpoints.sm.max}px;
  }

  @media (min-width: ${({ theme: { breakpoints } }) => breakpoints.md.break}px) {
    max-width: ${({ theme: { breakpoints } }) => breakpoints.md.max}px;
  }

  @media (min-width: ${({ theme: { breakpoints } }) => breakpoints.lg.break}px) {
    max-width: ${({ theme: { breakpoints } }) => breakpoints.lg.max}px;
  }

  @media (min-width: ${({ theme: { breakpoints } }) => breakpoints.xl.break}px) {
    max-width: ${({ theme: { breakpoints } }) => breakpoints.xl.max}px;
  }
`;

export const Wrapper = styled.div`
  ${WrapperStyles}/* section:not(.video-overlay-container) {
    margin: 17% 0;
  } */
`;

export const HeaderWrapper = styled.div`
  /* ${WrapperStyles} */
 /*   */

  ${responsive.md.query(css`
      margin-left: 55px;
      margin-right: 55px;
  `)}
`;