import React, { useRef, useState } from "react";
// import { GatsbyImage as Image } from "gatsby-plugin-image";
import { VideoOverlay } from "components";
import { HomeCarousel, SliderContainer } from "./styles";
import { Btn, Button, myArrow } from "../../globalElements";
import ArrowLeft from "../../../assets/svg/arrow-left.svg";
import ArrowRight from "../../../assets/svg/arrow-right.svg";

export const HomeSlider = ({ data }) => {
	const [video, setVideo] = useState({});
	const [visible, setVisible] = useState(false);
	const carousel = useRef(null);

	const handleVideo = (v) => {
		setVideo(v);
		setVisible(!visible);
	};

	return (
		<>
			<HomeCarousel
				renderArrow={myArrow}
				outerSpacing={0}
				ref={carousel}
				showArrows={false}
				pagination={false}
				enableMouseSwipe={false}
				className="fullWidth"
			>
				{data.map(
					(
						{
							button1Path,
							button1Title,
							videoLink,
							copy,
							id,
							image,
							title
						},
						i
					) => {
						return (
							<div key={id} style={{ display: "flex" }}>
								<img
									src={image.url}
									alt={image.alt}
									// loading="eager"
								/>
								<SliderContainer>
									<div className="wrapper">
										<div className="content-container">
											<h1>{title}</h1>
											<p>{copy}</p>
											<div className="button-block">
												<Button
													to={button1Path}
													primary={true}
												>
													{button1Title}
												</Button>
												{videoLink && (
													<Btn
														primary={true}
														onClick={() =>
															handleVideo({
																src: videoLink,
																poster:
																	image.gatsbyImageData
															})
														}
													>
														Watch Video
													</Btn>
												)}
											</div>
										</div>
										<div className="arrow-wrapper">
											{data[i - 1] && (
												<Btn
													onClick={() =>
														carousel.current.slidePrev()
													}
												>
													<ArrowLeft fill="white" />{" "}
													Previous
												</Btn>
											)}
											{data[i + 1] && (
												<Btn
													onClick={() =>
														carousel.current.slideNext()
													}
												>
													Next
													<ArrowRight fill="white" />
												</Btn>
											)}
										</div>
									</div>
								</SliderContainer>
							</div>
						);
					}
				)}
			</HomeCarousel>
			{visible && (
				<VideoOverlay
					visible={visible}
					video={video}
					onClick={() => setVisible(!visible)}
				/>
			)}
		</>
	);
};
