import React from 'react';
import styled from 'styled-components';

const List = styled.ul`
  display: ${(props) => (!props.visible ? 'none' : 'block')};
`;

export const Item = ({ visible, data, setter, variable, onClick }) => {
  return (
    <List visible={visible}>
      {data.map((value, i) => {
        if (value && value.length > 1) {
          return (
            <li key={`${i}+${value}`}>
              <label htmlFor={value}>
                {' '}
                <input
                  className='choice-box'
                  onChange={(e) => {
                    onClick(e.target.value, e.target.checked, setter, variable);
                  }}
                  key={value}
                  type='checkbox'
                  value={value}
                />
                {value}
              </label>
            </li>
          );
        } else {
          return null;
        }
      })}
    </List>
  );
};
