import React from "react";
import styled from "styled-components";
import { Header, Footer, Seo } from "components";
import useHeaderHeight from "../../hooks/useHeaderHeight";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Main = styled.main`
	${({ contain }) => contain ?? ""};
	overflow: ${({ collection }) => (collection ? "none" : "hidden")};
	margin-top: ${({ responsiveHeight: height, fullScreen }) =>
		fullScreen ? "0px" : height};
`;

export default function Layout({
	title,
	description,
	keywords,
	children,
	marginTop = "",
	fullScreen,
	isProduct,
	collection = false,
	contain = false,
	footer = true
}) {
	const responsiveHeight = useHeaderHeight();

	return (
		<>
			<Seo title={title} description={description} keywords={keywords} />
			<Header id="main-nav" product={isProduct} />
			<Main
				responsiveHeight={responsiveHeight}
				collection={collection}
				contain={contain}
				marginTop={marginTop}
				fullScreen={fullScreen}
			>
				{children}
			</Main>
			{footer && <Footer />}
			<ToastContainer
				position="top-center"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				pauseOnHover
			/>
		</>
	);
}
