import styled from 'styled-components';
import LogoBase from '../../../assets/svg/footer-logo.svg';

export const FooterEl = styled.footer`
  padding: 2rem 0;
  background-color: var(--dark-blue);
`;

export const Wrapper = styled.div`
  max-width: 1024px;
  margin: 0 auto;
  display: flex;

  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    > div,
    a {
      width: 100%;
      text-align: center;
    }

    a {
      width: 50%;
    }
  }

  > div {
    flex: 1;
    padding: 20px;
  }

  > a {
    flex: 1;
    padding: 0px;
  }
`;

export const Logo = styled(LogoBase)`
  height: auto;
  max-width: 100%;
  path {
    fill: white;
  }
`;

export const Copyright = styled.div`
  max-width: 1024px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;

  .buttons {
    height: 100%;
    display: flex;
    @media (max-width: 768px) {
      flex-direction: column;
    }
  }

  .icons {
    display: inline-flex;
    gap: 10px;
    a {
      transition: transform 0.3s ease-in-out;
      &:hover {
        transform: translateY(-5px);
      }
    }

    svg {
      fill: white;
    }
  }

  small {
    font-family: var(--font-regular);
    color: #c9c2c2;
    font-size: 13px;
    font-weight: 200;
  }
`;

export const Item = styled.div`
  h3 {
    color: #fff;
    font-weight: 600;
    padding-bottom: 7px;
  }

  li {
    padding-bottom: 5px;
    a {
      color: #a8a8ab;
      text-decoration: none;

      &:hover {
        color: #e5e5ee;
        transition: color 0.5s linear;
      }
    }
  }
`;
