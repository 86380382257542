import React, { useState, useRef, useEffect } from 'react';
import { VideoContainer, Play } from './styles';

export const Video = ({ src, height }) => {
  const video = useRef(null);
  const [play, setPlay] = useState(false);

  useEffect(() => {
    if (play) {
      video.current.play();
    } else {
      video.current.pause();
    }
  }, [play]);

  return (
    <VideoContainer videoHeight={height ?? ''}>
      <video
        src={src}
        type='video/mp4'
        track='none'
        loop
        onClick={() => setPlay(!play)}
        ref={video}
      ></video>
      <Play visible={!play} onClick={() => setPlay(!play)}>
        Play
      </Play>
    </VideoContainer>
  );
};
