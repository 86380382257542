import { uniq } from 'lodash-es';

export const getSizes = (variants) => {
  const sizeRegex = /^\w{1,}x\w{1,}$/g;

  const processedSizes = variants
    .map((item) => {
      return item.selectedOptions.find((option) => option.name === 'Size')
        ?.value;
    })
    .filter((size) => typeof size !== 'undefined' && sizeRegex.test(size));

  const arr = uniq(processedSizes);

  const { x, y } = arr.reduce(
    (acc, current) => {
      const [x, y] = current.split('x');

      const parsedX = parseInt(x, 10);
      const parsedY = parseInt(y, 10);

      return {
        x: !isNaN(parsedX) ? [...acc.x, parsedX] : acc.x,
        y: !isNaN(parsedY) ? [...acc.x, parsedY] : acc.y,
      };
    },
    { x: [], y: [] }
  );

  return {
    x: uniq(x.sort((a, b) => a - b)),
    y: uniq(y.sort((a, b) => a - b)),
  };
};

export const getFilterData = (variants) => {
  const collections = uniq(
    variants.map(
      (variant) =>
        variant.product.collections[0] && variant.product.collections[0].title
    )
  );

  const looks = uniq(variants.map((variant) => variant.product.productType));

  const finishes = uniq(
    variants.map(
      (variant) =>
        variant.selectedOptions.find((option) => option.name === 'Finish')
          ?.value
    )
  );
  const sizes = getSizes(variants);
  return { looks, finishes, sizes, collections };
};
