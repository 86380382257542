import styled from 'styled-components';

const visible = `
visibility: visible;
opacity: 1;
`;

export const Menu = styled.div`
  position: fixed;
  z-index: 100;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--primary-color);
  visibility: hidden;
  gap: 20px;
  opacity: 0;
  ${(props) => (props.visible ? visible : '')};
  transition: all 0.3s linear;

  a,
  button {
    color: #e5e5ee;
    font-size: 2rem;
    font-family: var(--font-bold);
    text-align: center;

    &:hover {
      color: white;
      transition: color 0.3s linear;
    }
  }

  button:not(#menu-book-button) {
    svg {
      path {
        stroke: white;
      }
    }
  }
`;
