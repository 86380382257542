import { useEffect, useState } from "react";
import useMedia from "./useMedia";

export const getHeight = () => {
  return parseInt(document.getElementById("main-nav").offsetHeight) + "px";
};

export default function useHeaderHeight() {
  const { isMobile, isDesktop, isLargeDesktop } = useMedia();
  const [responsiveHeight, setResponsiveHeight] = useState("");

  useEffect(() => {
    setResponsiveHeight(getHeight());
  }, [isMobile, isDesktop, isLargeDesktop]);

  return responsiveHeight;
}
