import React, { useState } from 'react';
import { Grid, Item, Overlay } from './OurPeopleGrid.styles';
import { GatsbyImage as Image } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { GrClose } from 'react-icons/gr';

const Title = styled.h1`
  font-family: var(--font-bold);
  color: black;
  font-size: 3.5rem;
  line-height: 5rem;
  margin: 2rem 0rem;
  vertical-align: top;
  word-break: break-word;

  @media (max-width: 768px) {
    font-size: 2.5rem;
    line-height: 2.8rem;
  }
`;

const MemberOverlay = ({ visible, member, onClick }) => {
  return (
    <Overlay visible={visible}>
      <div className='wrapper'>
        <Image
          className='wrapper-image'
          image={member.image?.gatsbyImageData}
          alt=''
        />
        <div className='content-container'>
          <h2>{member?.name}</h2>
          <span>{member?.title}</span>
          <p>{member?.description}</p>
          <button className='close' onClick={onClick}>
            <GrClose size={30} />
          </button>
        </div>
      </div>
    </Overlay>
  );
};

export const OurPeopleGrid = ({ team, title }) => {
  const [member, setMember] = useState({});
  const [visible, setVisible] = useState(false);

  const handleOverlay = (member) => {
    setMember(member);
    setVisible(!visible);
  };

  return (
    <>
      <Title>{title}</Title>
      <Grid length={team.length}>
        {team.map((member, i) => (
          <Item
            key={member.id}
            bkg={member.image.gatsbyImageData.images.fallback.src}
            tall={i % 2 !== 0}
          >
            <div className='overlay'>
              <h3>{member.name}</h3>
              <div className='member-info'>
                <span>{member.title}</span>
                <button onClick={() => handleOverlay(member)}>
                  Read about {member.name.split(' ')[0]}
                </button>
              </div>
            </div>
          </Item>
        ))}
      </Grid>
      <MemberOverlay
        visible={visible}
        member={member}
        onClick={() => setVisible(!visible)}
      />
    </>
  );
};
