import React, { useState, useRef, useEffect } from 'react';
import { VideoOverlayContainer, Play } from './styles';
import { GrClose, GrCirclePlay } from 'react-icons/gr';

export const VideoOverlay = ({ visible, video, onClick }) => {
  const videoRef = useRef(null);
  const [play, setPlay] = useState(false);

  useEffect(() => {
    videoRef.current.pause();
  }, []);

  useEffect(() => {
    if (play) {
      videoRef.current.play();
    } else {
      videoRef.current.pause();
    }
  }, [play]);

  return (
    <>
      <VideoOverlayContainer
        className='video-overlay-container fullWidth'
        visible={visible}
      >
        <div className='wrapper'>
          <button className='close' onClick={onClick}>
            <GrClose size={30} stroke='#fff' />
          </button>
          <div className='video'>
            <video
              poster={video.poster.images.fallback.src}
              src={video.src}
              loop
              preload='auto'
              onClick={() => setPlay(!play)}
              ref={videoRef}
            />
            <Play visible={!play} onClick={() => setPlay(!play)}>
              <GrCirclePlay size='100px' fill='#fff' />
            </Play>
          </div>
        </div>
      </VideoOverlayContainer>
    </>
  );
};
