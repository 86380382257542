import styled from 'styled-components';
import { Tabs as TabsBase } from 'react-tabs';

const visible = `
  opacity: 1;
  transform: translateX(0%);
  transition: transform 0.5s;
`;

export const Search = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 50vw;
  z-index: 100;
  opacity: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: white;
  transform: translateX(-100%);
  transition: transform 0.5s, opacity 5s ease-out;
  ${(props) => (props.visible ? visible : '')};

  @media (max-width: 900px) {
    width: 70vw;
  }

  @media (max-width: 760px) {
    width: 100vw;
  }
`;

export const Header = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
  padding: 1.025rem;
  background-color: var(--dark-blue);

  h2 {
    font-size: 20px;
    font-family: var(--font-bold);
  }

  input {
    border: 0;
    width: 200px;
    border-bottom: 2px solid black;
  }

  #exit {
    align-self: flex-end;
    color: white;
    text-align: right;
    font-size: 20px;
    svg {
      path {
        stroke: #fff;
      }
    }
  }
`;

export const TabHeader = styled.div`
  background-color: var(--dark-blue);
  width: 100%;
`;

export const Tabs = styled(TabsBase)`
  width: 100%;

  //Tab Header
  .react-tabs__tab-list {
    display: inline-flex;
    cursor: pointer;
    gap: 10px;
    margin: 0px 10px;
    background-color: var(--dark-blue);
  }

  //Tab
  .react-tabs__tab {
    background-color: var(--dark-blue);
    color: white;
    padding: 1rem;
    transition: all 0.3s linear;
    width: 100%;
    font-family: var(--font-bold);

    @media (max-width: 400px) {
      padding: 1rem 0rem;
      text-align: center;
    }

    &--selected {
      background-color: white;
      color: var(--dark-blue);
    }
  }

  //Pannel
  .react-tabs__tab-panel {
    transition: all 0.3s linear;
    margin: 0rem 1rem;
  }
`;

export const Overlay = styled.div`
  position: fixed;
  display: ${(props) => (props.visible ? 'block' : 'none')};
  top: 0;
  left: 0;
  transition: all 0.3s ease-in;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
  z-index: 100;
`;
