import React, { useState, useEffect } from 'react';
import { InputContainer } from './styles';
import { SearchGrid } from 'components';

export const QuickSearch = ({ input, products }) => {
  const [filtered, setFiltered] = useState([]);
  const [query, setQuery] = useState('');

  useEffect(() => {
    if (!query) return;
    filterByTitle();
  }, [query]);

  const filterByTitle = () => {
    const search = query.toLowerCase().trim();
    setFiltered(
      products.filter((product) => {
        const collection = product.collections[0]?.title.toLowerCase().trim();
        const title = product.title.toLowerCase().trim();
        return title.includes(search) || collection?.includes(search);
      })
    );
  };

  return (
    <InputContainer>
      <div className='input-info'>
        <h2>{input.title}</h2>
      </div>
      <label htmlFor={input.id} hidden>
        {input.title}
      </label>
      <input
        id={input.id}
        placeholder={input.placeholder}
        type='text'
        onChange={(e) => setQuery(e.target.value)}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            filterByTitle();
          }
        }}
      />
      <SearchGrid query={query} results={filtered} />
    </InputContainer>
  );
};
